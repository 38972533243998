<template>
  <div class="add-developer-bank-account">
    <fd-form class="card" @submit="submitForm">
      <modal-body class="p-2">
        <h4 class="mt-1 mb-4">{{ isEdit ? "Edit" : "Add" }} Bank Account</h4>

        <fd-select
          v-model="form.bank"
          class="col-12 mb-2"
          label="Bank"
          selectText="Select a bank"
          :options="bankOptions"
          :validators="[validator.required]"
        >
        </fd-select>
        <fd-input
          v-model="form.bankAccountName"
          class="col-12 mb-2"
          label="Account Name"
          name="bankAccountName"
          type="text"
          :validators="[validator.required]"
        >
        </fd-input>
        <fd-input
          v-model="form.bankAccountNumber"
          class="col-12 mb-2"
          label="Account No."
          name="bankAccountNumber"
          type="text"
          :validators="[validator.required]"
        >
        </fd-input>
        <fd-input
          v-model="form.swiftNumber"
          class="col-12 mb-2"
          label="Swift Number"
          name="swiftNumber"
          type="text"
        >
        </fd-input>
      </modal-body>
      <modal-footer class="row justify-content-end p-2">
        <fd-button class="mr-1" @click="$emit('cancel')">Cancel</fd-button>
        <fd-button type="submit" class="main">{{
          isEdit ? "Update" : "Add"
        }}</fd-button>
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import { bank } from "@/enums";
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    branchId: {
      type: [String, Number],
      required: true
    },
    bankAccount: {
      type: Object
    }
  },
  data: function () {
    return {
      bankOptions: this.$mapJsonToArray(bank, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      form: {
        bank: "",
        bankAccountName: "",
        bankAccountNumber: "",
        swiftNumber: ""
      },

      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    if (this.isEdit) {
      this.form = {
        bank: this.bankAccount.bank,
        bankAccountName: this.bankAccount.bankAccountName,
        bankAccountNumber: this.bankAccount.bankAccountNumber,
        swiftNumber: this.bankAccount.swiftNumber
      };
    }
  },
  methods: {
    submitForm() {
      if (this.isEdit) {
        this.$emit("update", this.bankAccount.id, {
          agencyBranchId: this.branchId,
          ...this.form
        });
      } else {
        this.$emit("create", {
          agencyBranchId: this.branchId,
          ...this.form
        });
      }
    }
  }
};
</script>

<style lang="scss">
.add-developer-bank-account {
  @media #{$breakpoint-up-sm} {
    min-width: 450px;
  }
}
</style>
